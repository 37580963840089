const photos = [
  { image: "/glimps/Gallary_1.jpg" },
  { image: "/glimps/Gallary_2.jpg" },
  { image: "/glimps/Gallary_3.jpg" },
  { image: "/glimps/Gallary_9.jpg" },
  { image: "/glimps/Gallary_8.jpg" },
  { image: "/glimps/Gallary_4.jpg" },
  { image: "/glimps/Gallary_5.jpg" },
  { image: "/glimps/Gallary_6.jpg" },
  { image: "/glimps/Gallary_10.jpg" },
  { image: "/glimps/Gallary_11.jpg" },
  { image: "/glimps/Gallary_12.jpg" },
  { image: "/glimps/1.jpg" },
  { image: "/glimps/2.jpg" },
  { image: "/glimps/3.jpg" },
  { image: "/glimps/4.jpg" },
  { image: "/glimps/8.jpg" },
  { image: "/glimps/6.jpg" },
  { image: "/glimps/7.jpg" },
  { image: "/glimps/9.jpg" },
  { image: "/glimps/10.jpg" },
  { image: "/glimps/11.jpg" },
  { image: "/glimps/12.jpg" },
  { image: "/glimps/13.jpg" },
  { image: "/glimps/14.jpg" },
];
export default photos;
