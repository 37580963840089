const products = [
  {
    key: "6mm-garment-elastic",
    label: "Garment Elastic (6MM)",
    count: 7,
    imageBase: "6mm_GarmentElastic",
    type: "garment",
  },
  {
    key: "8mm-garment-elastic",
    label: "Garment Elastic (8MM)",
    count: 6,
    imageBase: "8mm_GarmentElastic",
    type: "garment",
  },
  {
    key: "10mm-garment-elastic",
    label: "Garment Elastic (10MM)",
    count: 4,
    imageBase: "10mm_GarmentElastic",
    type: "garment",
  },
  {
    key: "12mm-garment-elastic",
    label: "Garment Elastic (12MM)",
    count: 6,
    imageBase: "12mm_GarmentElastic",
    type: "garment",
  },
  {
    key: "15mm-garment-elastic",
    label: "Garment Elastic (15MM)",
    count: 4,
    imageBase: "15mm_GarmentElastic",
    type: "garment",
  },
  {
    key: "20mm-plain-footwear-elastic",
    label: "Footwear Elastic (20MM)",
    count: 6,
    imageBase: "20mmPlain_FootwearElastic",
    type: "footwear",
  },
  {
    key: "28mm-plain-footwear-elastic",
    label: "Footwear Elastic (28MM)",
    count: 5,
    imageBase: "28",
    type: "footwear",
  },
  {
    key: "40mm-plain-footwear-elastic",
    label: "Footwear Elastic (40MM)",
    count: 9,
    imageBase: "40mmPlain_FootwearElastic",
    type: "footwear",
  },
  {
    key: "blue-victoria-garment-elastic",
    label: "Blue Victoria Garment Elastic",
    count: 4,
    imageBase: "BlueVictoria_GarmentElastic",
    type: "garment",
  },
  {
    key: "g9-garment-elastic",
    label: "G9 Garment Elastic",
    count: 4,
    imageBase: "G9_GarmentElastic",
    type: "garment",
  },
  {
    key: "green-victoria-garment-elastic",
    label: "Green Victoria Garment Elastic",
    count: 4,
    imageBase: "GreenVictoria_GarmentElastic",
    type: "garment",
  },
  {
    key: "honeycomb-footwear-elastic",
    label: "Honeycomb Footwear Elastic",
    count: 8,
    imageBase: "HoneyCombElastic_FootwearElastic",
    type: "footwear",
  },
  {
    key: "jacquard-elastic",
    label: "Jacquard Elastic",
    count: 7,
    imageBase: "jacquardelastic",
    type: "garment",
  },
  {
    key: "jari-footwear-elastic",
    label: "Jari Footwear Elastic",
    count: 5,
    imageBase: "Jari_FootwearElastic",
    type: "footwear",
  },
  {
    key: "maxim-garment-elastic",
    label: "Maxim Garment Elastic",
    count: 7,
    imageBase: "Maxim_GarmentElastic",
    type: "garment",
  },
  {
    key: "pro-garment-elastic",
    label: "Pro Garment Elastic",
    count: 7,
    imageBase: "Pro_GarmentElastic",
    type: "garment",
  },
  {
    key: "strap-footwear-elastic",
    label: "Strap Footwear Elastic",
    count: 7,
    imageBase: "Strap_FootwearElastic",
    type: "footwear",
  },
  {
    key: "tweel-footwear-elastic",
    label: "Tweel Footwear Elastic",
    count: 10,
    imageBase: "TweelElastic_FootwearElastic",
    type: "footwear",
  },
  {
    key: "yellow-maxim-garment-elastic",
    label: "Yellow Maxim Footwear Elastic",
    count: 5,
    imageBase: "YellowMaxim_GarmentElastics",
    type: "garment",
  },
  {
    key: "yellow-victoria-garment-elastic",
    label: "Yellow Victoria Garment Elastic",
    count: 5,
    imageBase: "YellowVictoria_GarmentElastic",
    type: "garment",
  },
  {
    key: "braided-elastic",
    label: "Braided Elastic",
    count: 6,
    imageBase: "Braided_elastic",
    type: "braided",
  },
  {
    key: "lurex-elastic",
    label: "Lurex Elastic",
    count: 4,
    imageBase: "lurex elastic",
    type: "lurex",
  },
  {
    key: "footwear-elastic-sandal",
    label: "Ffootwear Elastic For Sandal",
    count: 4,
    imageBase: "Footwear_ElasticSandal",
    type: "footwear",
  },
  {
    key: "footwear-elastic-boots",
    label: "Footwear Elastic for Boots",
    count: 3,
    imageBase: "Footwear_ElasticBoots",
    type: "footwear",
  },
  {
    key: "garments-necktape&drewstring",
    label: "Necktape & Drawstring Elastic",
    count: 2,
    imageBase: "Garments_NeckTape&DrawstringElastic",
    type: "garment",
  },
  {
    key: "garment-jacquard",
    label: "Jacquard Elastic",
    count: 2,
    imageBase: "Garments_Jacquard",
    type: "garment",
  },
  {
    key: "garment-digitalprint-elastic",
    label: "Digital Print Elastic",
    count: 1,
    imageBase: "Garment_DigitalPrintElastic",
    type: "garment",
  },
  // {
  //   key: "garment-elastic-6mm12mm",
  //   label: "Garment Elastic (6mm & 12mm)",
  //   count: 1,
  //   imageBase: "Garment_6mm12mmElastic",
  //   type: "garment",
  // },
];

export default products;
